import React from "react";
import style from "./DoubleColumns.module.scss";
import Section from "../../atoms/Section/Section";
import { ContainerFullCol } from "../../atoms/Containers/Conainers";

const DoubleColumns = ({ children }) => (
  <Section>
    <ContainerFullCol>
      <div className={style.container}>{children}</div>
    </ContainerFullCol>
  </Section>
);

export default DoubleColumns;

export const Left = ({ children }) => <div className={style.left}>{children}</div>;
export const Right = ({ children }) => <div className={style.right}>{children}</div>;
export const RightTop = ({ children }) => <div className={style.rightTop}>{children}</div>;
export const RightBottom = ({ children }) => <div className={style.rightBottom}>{children}</div>;
