import React from "react";
import style from "./ContactLineCTA.module.scss";
import Button from "../../elements/Buttons/Button";
import Text from "../../atoms/Text/Text";
import { ContainerFullCol } from "../../atoms/Containers/Conainers";

const ContactLineCTA = ({ callback }) => (
  <section>
    <ContainerFullCol>
      <div className={style.wrapper}>
        <div>
          <div className={style.title}>Vuoi saperne di più?</div>
          <Text>Non esitare, contattaci subito.</Text>
        </div>
        <div className={style.button}>
          <Button onClick={callback} black>
            Contattaci
          </Button>
        </div>
      </div>
    </ContainerFullCol>
  </section>
);

export default ContactLineCTA;
