import React from "react";
import cx from "classnames";
import style from "./Herobanner.module.scss";
import {ContainerRow} from "../../atoms/Containers/Conainers";
import Shape from "../Shape/Shape";

const Herobanner = ({children, backgroundUrl}) => (
  <section className={`relative ${style.wrapper}`}>
    <Shape />
    <ContainerRow>
      <div className={cx("col-12 col-md-6 my-10", style.left)}>{children}</div>
      <div className={cx("col-12 col-md-6 mt-5 mt-md-0 d-none d-md-flex", style.right)} style={{backgroundImage: `url(${backgroundUrl})`}} />
    </ContainerRow>
  </section>
);

export default Herobanner;
