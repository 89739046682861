import React from "react";
import Page from "../components/Page";
import Button from "../components/elements/Buttons/Button";
import Layout from "../components/Layout";
import Space from "../components/elements/Space/Space";
import FullImageBanner from "../components/components/FullImageBanner/FullImageBanner";
import ContactForm from "../components/components/ContactForm/ContactForm";
import Numbers, { Number } from "../components/components/Numbers/Numbers";
import ContactLineCTA from "../components/components/ContactLineCTA/ContactLineCTA";
import DoubleColumns, { RightTop, RightBottom, Left } from "../components/elements/DoubleColumns/DoubleColumns";
import { ContainerRow } from "../components/atoms/Containers/Conainers";
import UnderlineTitle from "../components/atoms/UnderlineTitle/UnderlineTitle";
import Text from "../components/atoms/Text/Text";
import Herobanner from "../components/elements/Herobanner/Herobanner";
import { scrollToContact } from "../utility/function";
import GreenTitle from "../components/atoms/GreenTitle/GreenTitle";
import Testimonial, { TestimonialSlider } from "../components/elements/Testimonial/Testimonial";
import ClientsTestimonialsImages from "../components/components/ClientsTestimonialsImages/ClientsTestimonialsImages";

const Assicurativo = () => (
  <Page
    title='Piattaforma assicurativa'
    description='Portiamo, direttamente alla tua scrivania, persone reali con un bisogno assicurativo. Ogni nominativo viene ceduto in esclusiva e può essere geolocalizzato.'
    image='/favicon.png'
  >
    <Layout navigationCallback={() => scrollToContact()}>
      <Herobanner backgroundUrl={"/herobanner/assicurativo.svg"}>
        <h1 className='mb-3'>Fatti raggiungere da nuovi clienti</h1>
        <Text>Portiamo, direttamente alla tua scrivania, persone reali con un bisogno assicurativo. Ogni nominativo viene ceduto in esclusiva e può essere geolocalizzato.</Text>
        <a href='https://app.e-leads.it/sign-up'>
          <Button customClass='mt-6'>Iscriviti gratis</Button>
        </a>

        <a href='https://app.e-leads.it/'>
          <Button customClass='mt-6 ml-3'>Accedi</Button>
        </a>

      </Herobanner>

      <Space mobile={10} table={15} desktop={20} />

      <Numbers
        icon='browser'
        text={
          <>
            Abbraccia anche tu il cambiamento
            <br />
            attraverso la lead generation
          </>
        }
      >
        <Number number={"800+"} text={"Consulenti iscritti"} />
        <Number number={"150.000+"} text={"Leads generati"} />
        <Number number={"9+"} text={"Rami assiucrativi"} />
      </Numbers>

      <Space mobile={10} table={4} desktop={10} />

      <ContactLineCTA callback={() => scrollToContact()} />

      <Space mobile={10} table={4} desktop={20} />

      <FullImageBanner />

      <Space mobile={20} table={20} desktop={20} />

      <ContainerRow>
        <div className='col-12 col-md-6'>
          <UnderlineTitle>Cosa facciamo</UnderlineTitle>
          <Text>
            Intercettiamo per conto tuo utenti che effettuano una ricerca on-line in merito ad una polizza assicurativa, e sono interessati ad entrare in contatto con un consulente esperto.<br></br>
            Noi offriamo a questi utenti quell'ultimo miglio che oggi percorrono da soli, mettendoli in contatto diretto con te.
          </Text>
        </div>
        <div className='col-12 col-md-6 mt-8 mt-md-0'>
          <UnderlineTitle>Come lo facciamo</UnderlineTitle>
          <Text>
            Generiamo nominativi qualificati attraverso un network di publisher premium affiliati al nostro marketplace.<br></br> Tutti i nominativi vengono sottoposti ad un intensivo processo di
            validazione delle informazioni fornite. Ogni nominativo viene ceduto in via esclusiva, in modo che solo tu possa presentare una proposta commerciale.
          </Text>
        </div>
      </ContainerRow>

      <Space mobile={20} table={20} desktop={20} />

      <DoubleColumns>
        <Left>
          <ClientsTestimonialsImages />
        </Left>
        <RightTop>
          <GreenTitle caps>Testimonials</GreenTitle>
          <h3 className='my-1'>Guarda cosa i nostri clienti dicono di noi</h3>
        </RightTop>
        <RightBottom>
          <TestimonialSlider className='mt-2'>
            <Testimonial name={"Gabriele Polgar"} position={"Co-Founder OPS Factory"} backgroundUrl={"/testimonials/testimonial_ops.jpg"}>
              <Text>
                E-leads ci consente di concentrarci sul nostro core-business: rendere più efficiente il lavoro delle agenzie assicurative. In E-leads abbiamo trovato un Partner per fornire un
                pacchetto completo al nuovo modello di Business che proponiamo al mondo assicurativo. Il servizio di E-Leads ci consente di esternalizzare il processo di lead-generation.
              </Text>
            </Testimonial>
            <Testimonial name={"Giorgio Oliveri"} position={"Ceo & Co-Founder Assit"} backgroundUrl={"/testimonials/testimonial_assit.png"}>
              <Text>
                L'idea di E-leads ci è piaciuta fin da subito. Abbiamo trovato un servizio che completa la nostra necessità di sviluppo su nuovi Clienti, scegliendo i profili più interessanti per noi.
                Il fatto di non aver nessun impegno all'acquisto e la possibilità di vedersi addebitati solamente i leads che sono veramente intenzionati a portare avanti una trattativa rappresentano
                per noi un aspetto determinante nella nostra scelta.
              </Text>
            </Testimonial>
            <Testimonial name={"Umberto Pasquali"} position={"Consulente Private INSURING e risk management"} backgroundUrl={"/testimonials/testimonial_umberto.png"}>
              <Text>
                E-leads mi ha aiutato ad incrementare in modo efficace la mia base clienti ed il mio giro d’affari. Quello che apprezzo particolarmente è che con loro mi vengono addebitati
                esclusivamente i leads che sono interessati ad iniziare una trattativa con me, tutti gli altri mi vengono rimborsati prontamente.
              </Text>
            </Testimonial>
            <Testimonial name={"Andrea Bizotto"} position={"Consulente finanziario e assicurativo presso Generali"} backgroundUrl={"/testimonials/testimonial_bizzotto.png"}>
              <Text>
                All'inizio ero scettico perchè un cliente nuovo che non ti ha mai visto (e mai ti vedrà), difficile che ti dia soldi o che ti faccia polizze nuove.
                Proseguendo ed aumentando il volume dei lead comprati è andata sempre meglio, le mie chiusure sono state maggiori e ogni telefonata nuova che facevo, avevo con me l'esperienza di quella prima.....e nel tempo ci sono stati numerosi contratti chiusi!!!
                La mia esperienza è estremamente positiva e la consiglio a chiunque.
              </Text>
            </Testimonial>
          </TestimonialSlider>
        </RightBottom>
      </DoubleColumns>

      <Space mobile={20} table={20} desktop={20} />
      {/*  Cosa aspetti?
      Iscriviti subito, è gratis!
      btn-> iscriviti ora */}
      <ContactLineCTA callback={() => scrollToContact()} />
      <Space mobile={20} table={20} desktop={20} />

      <ContactForm text_line1='Richiedi ora informazioni' />
    </Layout>
  </Page>
);

export default Assicurativo;
